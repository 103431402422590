import React from 'react';
import { Link } from 'react-router-dom';
import Video from '../assets/img/video.mp4';
import { useTranslation } from 'react-i18next';
import RectorSection from '../components/Home/RectorSection';
import ContactSection from '../components/Home/ContactSection';
import PartnersSection from '../components/Home/PartnersSection';
import AdvantagesSection from '../components/Home/AdvantagesSection';
import RectorSecondSection from '../components/Home/RectorSecondSection';

const Home = () => {
  const { t } = useTranslation();

  return (
    <main>
      <section className="re-section-video">
        <video className="video-background" src={Video} autoPlay loop muted></video>
        <div className="video-overlay"></div>
        <Link to={'/oddiy'} className="btn re-btn-light re-btn-register">
          {t('register')}
        </Link>
      </section>
      <RectorSection/>
      <AdvantagesSection/>
      {/* <RectorSecondSection/> */}
      <PartnersSection />
      <ContactSection/>
    </main>
  )
}

export default Home;