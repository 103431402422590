import axios from "axios";
import Swal from "sweetalert2";
import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Triangle } from "react-loader-spinner";
import React, { useEffect, useState } from "react";

const SimpleApplication = ({ setIsRegistered, setContractId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [faculties, setFaculties] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [selectedFaculty, setSelectedFaculty] = useState(null);
  const [selectedFacultyData, setSelectedFacultyData] = useState({});

  const initialContractData = {
    first_name: "",
    last_name: "",
    middle_name: "",
    passport: "",
    kafil_passport: "",
    jshshir: "",
    address: "",
    phone: "",
    extra_phone: "",
    name: "",
    time: "daytime",
    lang: "uz",
    type: "abuturent",
    passport_file: null,
  };

  const [contractData, setContractData] = useState(initialContractData);

  const setData = (e) => {
    if (e.target.name === "passport_file") {
      setContractData({
        ...contractData,
        passport_file: e.target.files[0],
      });
      return;
    }
    setContractData({
      ...contractData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let arr = Object.keys(contractData);
    for (let i = 0; i < arr.length; i++) {
      const element = contractData[arr[i]];
      if (!element) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("not-full-info"),
        });
        return;
      }
    }
    let formData = new FormData();
    Object.keys(contractData).forEach((x) => {
      formData.append(x, contractData[x]);
    });
    setLoading(true);
    try {
      let res = await axios.post(
        "https://admin.wise-texnikum.uz/api/contract",
        formData,
      );
      Swal.fire({
        icon: "success",
        title: `${t("conguratulations")}`,
        html: `${t("success")}<br/><br/> 
          <button id="download-contract" class="btn btn-success">${t(
            "download-contract",
          )}</button><br/><br/>
          <button id="download-document" class="btn btn-success">${t(
            "download-document",
          )}</button>`,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: "OK",
        allowOutsideClick: false,
        didRender: () => {
          const downloadContractBtn =
            document.getElementById("download-contract");
          const downloadDocumentBtn =
            document.getElementById("download-document");

          downloadContractBtn.addEventListener("click", () => {
            window.open(res.data.shartnoma, "_blank");
          });

          downloadDocumentBtn.addEventListener("click", () => {
            window.open(res.data.uchtomonli, "_blank");
          });
        },
      }).then(() => {
        // Clear form data after successful download
        setContractData(initialContractData);
      });
      setIsRegistered(true);
      setContractId(res.data.id);
      setFileUrl(
        `https://admin.wise-texnikum.uz/api/contract/${res.data.id}/download`,
      );
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error?.response?.data?.message || t("try-again"),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getFaculties = async () => {
      try {
        let res = await axios.get(
          "https://admin.renaissance-univer.uz/api/fakultet",
        );
        console.log(res);
        setFaculties(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getFaculties();
  }, []);

  useEffect(() => {
    const fetchFacultyData = async () => {
      setSelectedFacultyData({});
      setContractData({
        ...contractData,
        lang: "uz",
        time: "daytime",
      });
      try {
        let res = await axios.get(
          `https://admin.renaissance-univer.uz/api/fakultet_lang_time?name=${selectedFaculty}`,
        );
        setSelectedFacultyData(res.data);
        setContractData({
          ...contractData,
          lang: "",
          time: "",
        });
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedFaculty) {
      fetchFacultyData();
    }
  }, [selectedFaculty]);

  return (
    <div className="container my-5">
      <h1 className="text-center">WISE MEDICAL TEXNIKUMI</h1>
      <h2 className="text-warning">{t("online-register")}</h2>
      <form
        method="post"
        action="https://admin.renaissance-univer.uz/api/contract"
      >
        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="fname" className="form-label">
              {t("name")} *
            </label>
            <input
              type="text"
              id="fname"
              onChange={(e) => setData(e)}
              name="first_name"
              required
              placeholder="Ism..."
              value={contractData.first_name}
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="sourname" className="form-label">
              {t("surname")} *
            </label>
            <input
              type="text"
              name="last_name"
              onChange={(e) => setData(e)}
              id="sourname"
              required
              placeholder="Familiya..."
              value={contractData.last_name}
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="middle-name" className="form-label">
              {t("middle-name")} *
            </label>
            <input
              id="middle-name"
              type="text"
              name="middle_name"
              required
              onChange={(e) => setData(e)}
              placeholder="Otasining ismi..."
              value={contractData.middle_name}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="passport-field" className="form-label">
              {t("passport-number")} *
            </label>
            <ReactInputMask
              placeholder="AB 000 00 00"
              id="passport-field"
              type="text"
              name="passport"
              className="form-control"
              maskChar={null}
              mask="aa 999 99 99"
              required
              onChange={(e) => setData(e)}
              value={contractData.passport}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="jshshir-field" className="form-label">
              {t("jshshire")} *
            </label>
            <ReactInputMask
              id="jshshir-field"
              type="text"
              name="jshshir"
              required
              placeholder="0 000000 000 000 0"
              mask="9 999999 999 999 9"
              maskChar={null}
              onChange={(e) => setData(e)}
              value={contractData.jshshir}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="address" className="form-label">
              {t("address")} *
            </label>
            <input
              id="address"
              type="text"
              name="address"
              required
              onChange={(e) => setData(e)}
              placeholder="Istiqlol 13, Toshkent"
              value={contractData.address}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="phone-number" className="form-label">
              {t("phone-number")} *
            </label>
            <ReactInputMask
              name="phone"
              required
              id="phone-number"
              placeholder="+998 91 000 00 00"
              mask="+\9\98 (99) 999 99 99"
              maskChar={null}
              onChange={(e) => setData(e)}
              value={contractData.phone}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="additional-phone-number" className="form-label">
              {t("additional-phone-number")} *
            </label>
            <ReactInputMask
              id="additional-phone-number"
              name="extra_phone"
              required
              placeholder="+998 91 000 00 00"
              mask="+\9\98 (99) 999 99 99"
              maskChar={null}
              onChange={(e) => setData(e)}
              value={contractData.extra_phone}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="name" className="form-label">
              {t("faculty")} *
            </label>
            <select
              name="name"
              required
              onChange={(e) => {
                setData(e);
                setSelectedFaculty(e.target.value);
              }}
              className="select2 form-select"
              id="name"
              value={contractData.name}
            >
              <option value="">{t("select-faculty")}</option>
              <option value={"50910102 - Стоматология иши"}>
                {t("faculty-1")}
              </option>
              <option value={"50910205 - Функционал диагностика иши"}>
                {t("faculty-4")}
              </option>
              <option value={"50910204 - Фельдшерлик иши"}>
                {t("faculty-3")}
              </option>
              <option value={"50910203 - Хамширалик иши"}>
                {t("faculty-2")}
              </option>
              <option value={"50910401 - Фармация"}>{t("faculty-5")}</option>
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="time" className="form-label">
              {t("time")} *
            </label>
            <select
              name="time"
              required
              className="select2 form-select"
              disabled={true}
              id="time"
              value={contractData.time}
            >
              <option value="daytime">{t("daytime")}</option>
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="lang" className="form-label">
              {t("lang")} *
            </label>
            <select
              name="lang"
              className="select2 form-select"
              id="lang"
              disabled={true}
              value={contractData.lang}
            >
              <option value="uz">{t("uzbek")}</option>
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="passport-copy" className="form-label">
              {t("passport-copy")} *
            </label>
            <input
              type="file"
              required
              onChange={(e) => setData(e)}
              accept=".pdf"
              id="passport-copy"
              name="passport_file"
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="address" className="form-label">
              {t("passport-number-parent")} *
            </label>
            <input
              id="middle-name"
              type="text"
              name="kafil_passport"
              required
              onChange={(e) => setData(e)}
              value={contractData.kafil_passport}
              className="form-control"
            />
          </div>
        </div>
        {loading ? (
          <div className="re-loader-wrapper">
            <Triangle
              visible={true}
              height="100"
              width="100"
              color="#000"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <button
            className="btn re-btn-dark m-auto"
            type="submit"
            onClick={onSubmit}
          >
            {t("register")}
          </button>
        )}
      </form>
    </div>
  );
};

export default SimpleApplication;
