import React, { useState } from 'react';
import InputMask from "react-input-mask";
import { useTranslation } from 'react-i18next';
import YandexMap from '../../components/YandexMap';

const ContactSection = () => {
  const { t } = useTranslation();
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Construct the message to be sent to Telegram
    const message = `FISH: ${userName}\nTelefon raqam: ${userPhone}`;

    // Your Telegram bot API token
    const botToken = "6936017343:AAFUenMyj2SFFWt3rkKV4lIw31UutZtCq8Y";
    console.log("botToken", botToken)

    // The chat ID of the user or group where you want to send the message
    const chatId = "85009834";
    console.log("chatId", chatId)


    try {
      // Send the message using the Telegram Bot API
      const response = await fetch(
        `https://api.telegram.org/bot${botToken}/sendMessage`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            chat_id: chatId,
            text: message,
          }),
        }
      );

      if (response.ok) {
        // Handle success
        alert('Message sent successfully!');
        // Optionally, clear form fields or perform any other actions
        setUserName('');
        setUserPhone('');
      } else {
        // Handle failure
        alert('Failed to send message. Please try again later.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('An error occurred. Please try again later.');
    }
  };
  
  return (
    <section className="re-section-contact">
      <div className="re-contact-row">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="re-contact-inner">
                <h4 className="re-section-title">{t('contact-us')}</h4>
                <div className="re-sm-contact-map">
                  <YandexMap />
                </div>
                <form onSubmit={handleFormSubmit}>
                  <div className="re-form-group">
                    <label className="form-label">
                     {t('fullname')} <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="name"
                      placeholder={t('enter-fullname')}
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="re-form-group">
                    <label className="form-label">
                      {t('phone-number')} <span>*</span>
                    </label>
                    <InputMask
                      name="phone"
                      className="form-control"
                      placeholder={`+998 00 000 00 00`}
                      mask="+998 (99) 999 99 99"
                      maskChar={null}
                      id="contact-phone"
                      value={userPhone}
                      onChange={(e) => setUserPhone(e.target.value)}
                      required
                    />
                  </div>
                  <button type="submit" className="btn re-btn-dark">
                    {t('send')}
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-6 sm-hide"></div>
          </div>
        </div>
        <div className="re-full-right-half sm-hide">
          <YandexMap />
        </div>
      </div>
    </section>
  )
}

export default ContactSection;