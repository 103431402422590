import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/core.css";
import "./assets/css/theme-default.css";
import "./assets/css/demo.css";
import "./assets/css/custom.css";
import "./assets/css/slick.min.css";
import "./assets/css/style.css";
import "./assets/css/media.css"; 
import "boxicons/css/boxicons.min.css";
import Navbar from "./components/Navbar";
import About from "./pages/about";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import SimpleApplication from "./pages/simpleApplication";
import Home from "./pages/index";
import Header from "./components/Header";
import Footer from "./components/Footer";

function InnerApp({ isRegistered, setIsRegistered }) {
  const navigate = useNavigate();

  // Function to set timeout and navigate after 1 hour
  useEffect(() => {
    if (isRegistered) {
      const timeoutId = setTimeout(() => {
        setIsRegistered(false);
        navigate("/");
      }, 3600000); // 1 hour in milliseconds

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isRegistered, navigate]);

  return null;
}

function App() {
  const [isRegistered, setIsRegistered] = useState(false);
  const [contractId, setContractId] = useState("");

  return (
    <BrowserRouter>
      <Navbar/>
      <Header />
      <InnerApp isRegistered={isRegistered} setIsRegistered={setIsRegistered} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/oddiy"
          element={
            <SimpleApplication
              setIsRegistered={setIsRegistered}
              setContractId={setContractId}
            />
          }
        />
        <Route
          path="/haqida"
          element={
            <About/>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
