import React from 'react';
import direktorImage from '../../assets/img/rector.webp';
import { Link } from 'react-router-dom';

const RectorSecondSection = () => {
  return (
    <section className="re-section-rector-two">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="re-content-rec-img">
              <img src={direktorImage} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="re-content-rector">
              <h2 className="re-section-title">
                WISE MEDICAL TEXNIKUM O'QUV ISHLAR BO'YICHA DIREKTORI MUROJATI
              </h2>
              <p className="re-content-desc">
                Xurmatli talabalar! <br />
                Bizning "WISE MEDICAL TEXNIKUMI" ni muvaffaqiyatli
                tamomlagan bitiruvchilar suhbat orqali oily ta'lim
                muassasalarida ikkinchi bosqichdan o'qishni davom ettirish
                huquqiga ega.
              </p>
              <h3 className="re-content-rector-name">Xo'jabekov A.U.</h3>
              <p className="re-content-position">
                WISE MEDICAL TEXNIKUMI o'quv ishlar bo'yicha direktor
              </p>
              <Link className="btn re-btn-dark" to={'/haqida'} >Batafsil</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RectorSecondSection
