import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import direktorImage from '../../assets/img/direktor.jpg';

const RectorSection = () => {
  const { t } = useTranslation();

  return (
    <section className="re-section-rector">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="re-content-rector">
              <h2 className="re-section-title">
                {t('rector-speech-title')}
              </h2>
              <p className="re-content-desc">
                {t('greeting')} <br />
                {t('rector-speech-text')}
              </p>
              <h3 className="re-content-rector-name">Xo'jabekov A.U.</h3>
              <p className="re-content-position">
                {t('tech-director')}
              </p>
              <Link className="btn re-btn-dark" to={'/haqida'}>{t('more')}</Link>
            </div>
          </div>
          <div className="col-md-6">
            <div className="re-content-rec-img">
              <img src={direktorImage} className="img-fluid w-100" alt="direcotor" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RectorSection;