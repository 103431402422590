import React from 'react';
import { Link } from 'react-router-dom';
import Image1 from '../assets/img/tex_1.jpg';
import Image2 from '../assets/img/tex_2.jpg';
import Image3 from '../assets/img/tex_3.jpg';
import Image4 from '../assets/img/tex_4.jpg';
import Image5 from '../assets/img/tex_5.jpg';
import { useTranslation } from 'react-i18next';
import LicenseDocument from '../assets/docs/doc2.pdf';
import Document from '../assets/docs/doc1.pdf';

const About = () => {
  const { t } = useTranslation();

  return (
    <main>
      <section>
        <div className="container">
          <nav className="re-breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={'/'}>{t('home')}</Link>
              </li>
              <li className="breadcrumb-item active">{t('about-us')}</li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-md-8">
              <div className="re-content">
                <div className="re-content-body">
                  <h1 className="re-university-name">WISE MEDICAL TEXNIKUM</h1>
                  <p className="re-university-desc">
                    {t('about-1')}
                  </p>
                  <p className="re-university-desc">
                    {t('about-2')}
                    <ul className="re-university-fac">
                      <li>{t('faculty-1')}</li>
                      <li>{t('faculty-2')}</li>
                      <li>{t('faculty-3')}</li>
                      <li>{t('faculty-4')}</li>
                      <li>{t('faculty-5')}</li>
                    </ul>
                  </p>
                  <p className="re-university-desc">
                    {t('about-3')}
                  </p>
                  <h5 className="re-university-info-title">Uchtepa filial</h5>
                  <p className="re-university-desc">
                    Sergeli tumanida joylashgan filial binosining umumiy maydoni 2411m2, o'quv auditoriyalari soni 17 ta va har biri 48m2, kutubxona, oshxona, o'quv laboratoriyasi, ma'naviyat xonasi, tashqi va ichki sport maydonlari va laborator diagnostika xolari mavjud. 
                  </p>
                  <h5 className="re-university-info-title">{t('sergeli-branch')}</h5>
                  <p className="re-university-desc">
                    {t('sergeli-desc')}
                  </p>
                  <h5 className="re-university-info-title">{t('our-goal')}</h5>
                  <p className="re-university-desc">
                    {t('about-4')}
                    <br />
                    {t('about-5')}
                  </p>
                  <h5 className="re-university-info-title">{t('doc-title')}</h5>
                  <div className="re-university-doc">
                    <div className="re-doc-item">
                      <div className="re-doc-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M11.6663 1.66663H4.99967C4.55765 1.66663 4.13372 1.84222 3.82116 2.15478C3.5086 2.46734 3.33301 2.89127 3.33301 3.33329V16.6666C3.33301 17.1087 3.5086 17.5326 3.82116 17.8451C4.13372 18.1577 4.55765 18.3333 4.99967 18.3333H14.9997C15.4417 18.3333 15.8656 18.1577 16.1782 17.8451C16.4907 17.5326 16.6663 17.1087 16.6663 16.6666V6.66663L11.6663 1.66663Z" stroke="#05913a" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M13.3337 14.1666H6.66699" stroke="#05913a" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M13.3337 10.8334H6.66699" stroke="#05913a" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M8.33366 7.5H7.50033H6.66699" stroke="#05913a" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M11.667 1.66663V6.66663H16.667" stroke="#05913a" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                      </div>
                      <a href={LicenseDocument} download>{t('license')}</a>
                    </div>
                    <div className="re-doc-item">
                      <div className="re-doc-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M11.6663 1.66663H4.99967C4.55765 1.66663 4.13372 1.84222 3.82116 2.15478C3.5086 2.46734 3.33301 2.89127 3.33301 3.33329V16.6666C3.33301 17.1087 3.5086 17.5326 3.82116 17.8451C4.13372 18.1577 4.55765 18.3333 4.99967 18.3333H14.9997C15.4417 18.3333 15.8656 18.1577 16.1782 17.8451C16.4907 17.5326 16.6663 17.1087 16.6663 16.6666V6.66663L11.6663 1.66663Z" stroke="#05913a" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                          <path d="M13.3337 14.1666H6.66699" stroke="#05913a" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                          <path d="M13.3337 10.8334H6.66699" stroke="#05913a" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                          <path d="M8.33366 7.5H7.50033H6.66699" stroke="#05913a" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                          <path d="M11.667 1.66663V6.66663H16.667" stroke="#05913a" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                      </div>
                      <a href={Document} download>{t('document')}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="re-sidebar">
                <div className="re-content-img-wrp">
                  <h3 className="re-content-title">{t('tech-pictures')}</h3>
                  <div className="re-content-img-item">
                    <div className="re-content-img">
                      <img src={Image1} alt="" />
                    </div>
                    <div className="re-content-img-desc">{t('lab-room')}</div>
                  </div>
                  <div className="re-content-img-item">
                    <div className="re-content-img">
                      <img src={Image2} alt="" />
                    </div>
                    <div className="re-content-img-desc">{t("library")}</div>
                  </div>
                  <div className="re-content-img-item">
                    <div className="re-content-img">
                      <img src={Image3} alt="" />
                    </div>
                    <div className="re-content-img-desc">{t('canteen')}</div>
                  </div>
                  <div className="re-content-img-item">
                    <div className="re-content-img">
                      <img src={Image4} alt="" />
                    </div>
                    <div className="re-content-img-desc">{t('clinic')}</div>
                  </div>
                  <div className="re-content-img-item">
                    <div className="re-content-img">
                      <img src={Image5} alt="" />
                    </div>
                    <div className="re-content-img-desc">{t('room')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default About;