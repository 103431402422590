import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from "../../src/assets/img/logo.png";
import Facebook from "../../src/assets/img/ic_facebook.svg";
import Telegram from "../../src/assets/img/ic_telegram.svg";
import Location from "../../src/assets/img/ic_location.svg";
import Instagram from "../../src/assets/img/ic_instagram.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="container">
        <div className="re-footer-container">
          <div className="re-footer-group">
            <div className="re-footer-logo-wrp">
              <Link to={'/'}>
                <img className="re-footer-logo" src={Logo} alt="" />
              </Link>
            </div>
            <div className="re-footer-desc">
              {t('footer-text')}
            </div>
          </div>
          <div className="re-footer-group">
            <div className="re-footer-label">{t('working-hour')}</div>
            <ul className="nav flex-column">
              <li className="nav-item">{t('working-hour-1')}</li>
              <li className="nav-item">{t('working-hour-2')}</li>
            </ul>
            <div className="re-footer-label">{t('social-media')}</div>
            <div className="re-footer-socials">
              <Link
                to={'https://www.instagram.com/wisemedicaltexnikumi?igsh=MW12aW9zZzl1cmhwZQ=='}
                className="re-footer-ic">
                <img src={Instagram} />
              </Link>
              <Link className="re-footer-ic" to={'/'}>
                <img src={Facebook} />
              </Link>
              <Link className="re-footer-ic" to={"https://t.me/wisemedtexnikum"}>
                <img src={Telegram} />
              </Link>
            </div>
          </div>
          <div className="re-footer-group-right">
            <div className="re-footer-label">{t('phone-number')}</div>
            <div className="re-phone">
              <Link to={'tel:+998771380055'}
                ><span className="re-text-blue">+998 77 138 00 55</span></Link>
            </div>
            <div className="re-footer-label">{t('email')}</div>
            <div className="re-email">
              <Link to={"mailto:example@gmail.com"}><span>example@gmail.com</span></Link>
            </div>
            <div className="re-footer-info">
              <div className="re-footer-ic re-footer-ic-sm">
                <img src={Location} alt="location" />
              </div>
              <div>14a, Yangi Sergeli ko'ch, Sergeli t, Tashkent sh</div>
              <div className="re-footer-ic">
                <img src={Location} alt="location" />
              </div>
            </div>
            <div className="re-footer-info">
              <div className="re-footer-ic re-footer-ic-sm">
                <img src={Location} alt="location" />
              </div>
              <div>18, Lutfiy ko'ch, Uchtepa t, Tashkent sh</div>
              <div className="re-footer-ic">
                <img src={Location} alt="location" />
              </div>
            </div>
          </div>
        </div>
        <div className="re-footer-bottom">
          <div className="re-footer-copyright">
            © 2024 WISE MEDICAL TEXNIKUM. All rights reserved.
          </div>
          <div className="re-footer-developer">
            Developed by <Link href="#">Disoft</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;