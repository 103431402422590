import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import Partner from "../../assets/img/лого12В.png";
import Right from "../../assets/img/ic_slick_right.svg";
import Left from "../../assets/img/ic_slick_left.svg";

const PartnersSection = () => {
  const { t } = useTranslation();

  function SampleNextArrow({ onClick }) {
    // Changed onclick to onClick
    return (
      <button
        className="slick-next slick-arrow slick-white-arrow"
        type="button"
        onClick={onClick}
      >
        <img src={Right} alt="Next" />
      </button>
    );
  }

  function SamplePrevArrow({ onClick }) {
    // Changed onclick to onClick
    return (
      <button
        className="slick-prev slick-arrow slick-white-arrow"
        type="button"
        onClick={onClick}
      >
        <img src={Left} alt="Previous" />
      </button>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="re-section-partners">
      <div className="container">
        <h2 className="re-section-title">{t("our-partners")}</h2>
        <div className="re-partners-slider">
          <Slider {...settings}>
            <div className="re-partner-brand-item-wrapper">
              <div className="re-partner-item">
                <img
                  src={Partner}
                  className="img-fluid w-100 p-3"
                  alt="partner"
                />
              </div>
              {/* <h4 className="re-partner-title">
                Dentals Pharma – Stomatologik plomba materiallari ishlab
                chiqaruvchisi
              </h4> */}
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
