import React from 'react';
import Navbar from './Navbar';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from "../assets/img/logo.png";
import { useTranslation } from 'react-i18next';
import Facebook from "../assets/img/ic_facebook.svg";
import Telegram from "../assets/img/ic_telegram.svg";
import Instagram from "../assets/img/ic_instagram.svg";
import Location from "../../src/assets/img/ic_location.svg";

const MenuModal = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const handleCloseModal = () => handleClose(true);

  return (
    <Modal
      show={show} 
      onHide={handleClose} 
      id="menuModal" 
      size="fullscreen"
      class="modal fade"
    >
        <div class="modal-content">
          <Navbar />
          <header class="re-header">
            <div class="container">
              <div class="re-navbar">
                <div class="re-navbar-left sm-hide">
                  <Link to={'/oddiy'} onClick={handleCloseModal}  class="re-btn-light">{t('register')}</Link>
                </div>
                <div class="re-navbar-center">
                  <Link to={'/'} onClick={handleCloseModal}  class="re-logo">
                    <img src={Logo} alt="logo" />
                  </Link>
                </div>
                <div class="re-navbar-right">
                  <nav class="navbar-expand-lg">
                  <ul className="navbar-nav navbar-icons sm-hide">
                    <li className="nav-item">
                      <Link
                        to={'https://www.instagram.com/wisemedicaltexnikumi?igsh=MW12aW9zZzl1cmhwZQ=='}
                        className="re-footer-ic nav-link">
                        <img src={Instagram} alt="" />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={''} className="re-footer-ic nav-link">
                        <img src={Facebook} alt="" />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={'https://t.me/wisemedtexnikum'}
                        className="re-footer-ic nav-link">
                        <img src={Telegram} alt="" />
                      </Link>
                    </li>
                  </ul>
                  </nav>
                </div>
                <div class="re-mob-nav">
                  <button
                    class="btn re-hamburger"
                    onClick={handleCloseModal} 
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 6L6 18" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M6 6L18 18" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div class="modal-body">
            <div className="re-footer-group">
              <div className="re-footer-label">{t('working-hour')}</div>
              <ul className="nav flex-column">
                <li className="nav-item">{t('working-hour-1')}</li>
                <li className="nav-item">{t('working-hour-2')}</li>
              </ul>
              <div className="re-footer-label">{t('social-media')}</div>
              <div className="re-footer-socials">
                <Link
                  to={'https://www.instagram.com/wisemedicaltexnikumi?igsh=MW12aW9zZzl1cmhwZQ=='}
                  className="re-footer-ic">
                  <img src={Instagram} />
                </Link>
                <Link className="re-footer-ic" to={'/'}>
                  <img src={Facebook} />
                </Link>
                <Link className="re-footer-ic" to={"https://t.me/wisemedtexnikum"}>
                  <img src={Telegram} />
                </Link>
              </div>
            </div>
            <div className="re-footer-group-right">
              <div className="re-footer-label">{t('phone-number')}</div>
              <div className="re-phone">
                <Link to={'tel:+998771380055'}
                  ><span className="re-text-blue">+998 77 138 00 55</span></Link>
              </div>
              <div className="re-footer-label">{t('email')}</div>
              <div className="re-email">
                <Link to={"mailto:example@gmail.com"}><span>example@gmail.com</span></Link>
              </div>
              <div className="re-footer-info">
                <div className="re-footer-ic re-footer-ic-sm">
                  <img src={Location} alt="location" />
                </div>
                <div>14a, Yangi Sergeli ko'ch, Sergeli t, Tashkent sh</div>
                <div className="re-footer-ic">
                  <img src={Location} alt="location" />
                </div>
              </div>
              <div className="re-footer-info">
                <div className="re-footer-ic re-footer-ic-sm">
                  <img src={Location} alt="location" />
                </div>
                <div>18, Lutfiy ko'ch, Uchtepa t, Tashkent sh</div>
                <div className="re-footer-ic">
                  <img src={Location} alt="location" />
                </div>
              </div>
            </div>
          </div>
        </div>
    </Modal>
  )
}

export default MenuModal