// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dm-main-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.dm-main-links .nav-item {
  max-width: 400px;
  width: 100%;
  margin-bottom: 1.25rem;
}

.dm-main-links .nav-link {
  font-size: 1.25rem;
  font-weight: 500;
}

.dm-main-links .nav-link:hover {
  color: #000;
}

.navbar-brand {
  padding: 0;
}

.header-logo {
  height: 3.8rem;
}

.navbar {
  z-index: 3 !important;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/custom.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".dm-main-links {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  width: 100%;\n  align-items: center;\n}\n\n.dm-main-links .nav-item {\n  max-width: 400px;\n  width: 100%;\n  margin-bottom: 1.25rem;\n}\n\n.dm-main-links .nav-link {\n  font-size: 1.25rem;\n  font-weight: 500;\n}\n\n.dm-main-links .nav-link:hover {\n  color: #000;\n}\n\n.navbar-brand {\n  padding: 0;\n}\n\n.header-logo {\n  height: 3.8rem;\n}\n\n.navbar {\n  z-index: 3 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
