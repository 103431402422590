import React, { useState } from 'react';
import Logo from "../assets/img/logo.png";
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Facebook from "../assets/img/ic_facebook.svg";
import Telegram from "../assets/img/ic_telegram.svg";
import Hamburger from "../assets/img/ic_hamburger.svg";
import Instagram from "../assets/img/ic_instagram.svg";
import MenuModal from './MenuModal';

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <header className="re-header">
        <div className="container">
          <div className="re-navbar">
            <div className="re-navbar-left sm-hide">
              {currentPath !== '/oddiy' && (
                <Link to={'/oddiy'} className="re-btn-light">{t('register')}</Link>
              )}
              {currentPath == '/oddiy' && (
                <Link to={'/'} className="re-btn-light">{t('home')}</Link>
              )}
            </div>
            <div className="re-navbar-center">
              <Link to={'/'} className="re-logo">
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            <div className="re-navbar-right">
              <nav className="navbar-expand-lg">
                <ul className="navbar-nav navbar-icons sm-hide">
                  <li className="nav-item">
                    <Link
                      to={'https://www.instagram.com/wisemedicaltexnikumi?igsh=MW12aW9zZzl1cmhwZQ=='}
                      className="re-footer-ic nav-link">
                      <img src={Instagram} alt="" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={''} className="re-footer-ic nav-link">
                      <img src={Facebook} alt="" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={'https://t.me/wisemedtexnikum'}
                      className="re-footer-ic nav-link">
                      <img src={Telegram} alt="" />
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="re-mob-nav">
              <button
                className="btn re-hamburger"
                onClick={handleShowModal}
              >
                <img alt="Menu" src={Hamburger} />
              </button>
            </div>
          </div>
        </div>
      </header>
      <MenuModal show={showModal} handleClose={handleCloseModal} />
    </>
  )
}

export default Header;