import i18n from '../utils/i18n';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Phone from '../assets/img/images.png';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

const Navbar = () => {
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState('uz');

  useEffect(() => {
    const savedLanguage = localStorage.getItem('i18nextLng') || 'uz';
    setCurrentLanguage(savedLanguage);
    i18n.changeLanguage(savedLanguage);
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
    setCurrentLanguage(lang); 
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="re-nav-contact">
          <span>{t('call-center')}</span>
          <img src={Phone} alt="phone" />
          <Link className="re-contact-phone" to={'tel:+998(78)147-00-55'}>
            +998(78) 147-00-55
          </Link>
          <Link className="re-contact-phone" to={'tel:+998(78)147-00-55'}>
            +998(78) 148-00-55
          </Link>
        </div>
        <Dropdown className="dropdown">
          <Dropdown.Toggle
            className="btn dropdown-toggle re-language-dropdown"
            type="button">
            {currentLanguage === 'ru' ? 'Русский' : "O'zbek"}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu re-dropdown-menu">
            <Dropdown.Item
              className={`btn rounded-0 dropdown-item ${currentLanguage === 'ru' ? 'active' : ''}`}
              onClick={() => changeLanguage('ru')}
            >
              Русский
            </Dropdown.Item>
            <Dropdown.Item
              className={`btn rounded-0 dropdown-item ${currentLanguage === 'uz' ? 'active' : ''}`}
              onClick={() => changeLanguage('uz')}
            >
              O'zbek
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </nav>
  );
};

export default Navbar;